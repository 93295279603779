import React, { useEffect, Suspense, ReactElement } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import Loader from '@components/common/Loader/Loader';
import createStore from '@store/index';

const Page = React.lazy(() => import('@views/Page/PageContainer'));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const store = createStore();

function App(): ReactElement {
  useEffect(() => {
    (window as any).header?.init().then((auth: any) => {
      if (auth.previousState) {
        window.location.href = auth.previousState;
      }
    });
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <Suspense fallback={<Loader />}>
          <Switch>
          <Route exact path='/aug' component={() => { 
              window.location.href = process.env.REACT_APP_URL + '/user-groups'; 
              return null;
            }}/>
            
            <Route exact path='/' component={() => { 
              window.location.href = 'https://www.arduino.cc'; 
              return null;
            }}/>
            <Route exact path="/:slug?" component={Page} />
            <Route render={() => <Redirect to={`/`} />} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
